import { NavLink, NavLinkProps } from "react-router-dom";
import { authProvider } from "@/auth";
import { useFlags } from "launchdarkly-react-client-sdk";

/**
 *
 * Main navigation menu on the left side.
 */

function navigation(flags: any) {
  let userCanViewCoachDataTab = flags.coachDataTab && authProvider.isCoach();
  let userCanViewInstructionalLog = authProvider.instructionalLogUrl || authProvider.canAccessInstructionalLog();
  let nav = [
    userCanViewInstructionalLog
      ? {
        name: "Instructional Log",
        pathname: "/instructional-log",
      }
      : null,
    {
      name: "Student Data",
      pathname: "/student-data",
    },
    (userCanViewCoachDataTab || authProvider.isEmployee()) ? { // TEMPORARILY ONLY EMPLOYEES
      name: "Coach Data",
      pathname: "/meeting-report",
    } : null,
    {
      name: "Instructor Objectives",
      pathname: "/instructor-objectives",
    },
    {
      name: "My Recordings",
      pathname: "/my-recordings",
    },
    {
      name: "Curriculum",
      pathname: "/curriculum",
    },

    flags.nativeInstructorScoring && authProvider.isCoach() ? {
      name: "Instructor Scoring Form",
      pathname: "/instructor-scoring",
    } : null,
    flags.nativeCoachingMeetingSummary && authProvider.isCoach() ? {
      name: "Coach Meeting Form",
      pathname: "/coaching-meeting",
    } : null,
    flags.coachManagement ? {
      name: "Coach Management",
      pathname: "/coach-management",
    } : null,
    flags.vacationDatesAdmin && authProvider.isDistrictAdmin() ? {
      name: "Vacation Dates",
      pathname: "/admin/vacations",
    } : null,
    userCanViewInstructionalLog
      ? {
        name: "Tab 7",
        pathname: "/tab7",
      }
      : null,
  ];

  return nav;
}

function Nav() {
  let flags = useFlags();
  let nav = navigation(flags);
  if (authProvider.userClassification === "Direct to Consumer Customer") {
    nav = nav.filter((item) => item?.name === "Curriculum");
  }

  return (
    <menu className="w-72 bg-ponce text-white flex flex-col">
      {nav.map((navItem) => {
        return (
          navItem && (
            <StyledNavLink
              key={navItem.pathname}
              to={{
                pathname: navItem.pathname,
                search: location.search,
              }}
            >
              {navItem.name}
            </StyledNavLink>
          )
        );
      })}
    </menu>
  );
}

export default Nav;

function StyledNavLink(props: NavLinkProps) {
  return (
    <NavLink
      className={({ isActive }) => {
        return `px-4 py-4 whitespace-nowrap ${isActive ? "bg-white text-blue-700 " : ""} `
      }}
      to={props.to} >{props.children}</NavLink>
  )
}