import { useContext, useMemo } from "react";
import { AverageCalculationProcessed, calculateStudentAverage, getInstructorsById, getStudentCountByInstructor, LogData, swapStudentInstructorMapping } from "./utils";
import HeaderRow from "./HeaderRow";
import AttendanceRow from "./AttendanceRow";
import InstructorSection from "./InstructorSection";
import { Day } from "@/lib/core/day";
// import { useInstructionalLog } from "@/hooks/useInstructionalLog";
import { SchoolAssociationOutput, useInstructorsForSchool, StudentInstructorMap } from "@/hooks/useSchoolAssociations";
import { MaxCurriculumOutput } from "@/hooks/useMaxCurriculum";
import classNames from "classnames";
import { SelectedSchoolContext } from "@/contexts/SelectedSchool";
import { InstructionalLogOutput } from "@/hooks/useInstructionalLog";

type Props = {
    latestCurriculumByStudent: MaxCurriculumOutput,
    startDate: Day;
    endDate: Day;
    // Instructor/Student pairs
    setLog: any; // Probably not needed
    holidays: Day[];
    students: SchoolAssociationOutput | undefined;
    logData: InstructionalLogOutput;
    upsert: any;
    studentAssignmentsAccessLevel: string | undefined;
}

const HOLIDAY_CLASSES = "bg-gray-100";
const TODAY_CLASSES = "border border-2 border-black";

const LogTable: React.FC<Props> = ({ latestCurriculumByStudent, upsert, startDate, endDate, holidays, students, logData, studentAssignmentsAccessLevel }) => {
    const writeable = logData.writeable ?? false;
    const log = logData.entries;
    const today = Day.today();
    const today_str = today.toString();
    const dates: Day[] = useMemo(() => {
        let dates: Day[] = [];
        startDate.callOverRange(endDate, date => {
            if (date.isWeekend()) {
                return;
            }
            dates.push(date.clone());
        });
        return dates;
    }, [startDate, endDate]);
    const holidaysSet = new Set(holidays.map(date => date.toString()));
    const studentInstructorMap = useMemo(() => swapStudentInstructorMapping(students?.students), [students]);
    const studentCountByInstructor = useMemo(() => getStudentCountByInstructor(students?.students), [students]);
    const instructorsById = useMemo(() => getInstructorsById(students?.students), [students]);
    const currentSchoolId = useContext(SelectedSchoolContext);
    if (!currentSchoolId) {
        // This should never happen
        throw new Error("No school selected");
    }
    const { updatingInstructors } = useInstructorsForSchool(currentSchoolId);
    const averageCalculation: AverageCalculationProcessed | undefined = calculateAverages(startDate, endDate, studentCountByInstructor, studentInstructorMap, log)

    if (!log || !averageCalculation) {
        return <div>Loading...</div>
    }

    return (
        <table className={classNames("table-fixed", "border-collapse", "w-full", { "opacity-50": updatingInstructors })}>
            <colgroup >
                <col />
                <col />
                <col />
                <col />
                <col />
                {dates.map(date => {
                    const ds = date.toString();
                    if (ds === today_str) {
                        let classes = `${TODAY_CLASSES}`;
                        if (holidaysSet.has(ds)) {
                            classes += ` ${HOLIDAY_CLASSES}`;
                        }
                        return <col key={ds} className={classes} />
                    } else
                        if (holidaysSet.has(ds)) {
                            return <col key={ds} className={HOLIDAY_CLASSES} />
                        } else {
                            return <col key={ds} />
                        }
                })}
            </colgroup>
            <thead>
                <AttendanceRow today={today_str} dates={dates} holidays={holidaysSet} log={averageCalculation.school} />
                <HeaderRow dates={dates} />
            </thead>
            <tbody>
                {[...((students?.students.entries() ?? []))].sort((a, b) => {
                    const aName = instructorsById.get(a[0]) ?? a[0];
                    const bName = instructorsById.get(b[0]) ?? b[0];
                    if (aName === "UNASSIGNED") {
                        return -1;
                    }
                    if (bName === "UNASSIGNED") {
                        return 1;
                    }
                    return aName.localeCompare(bName);
                }).map(([instructor, studentList], idx) => (
                    <InstructorSection
                        writable={writeable}
                        key={instructor}
                        rowNumber={idx}
                        average={averageCalculation[instructor]}
                        curriculumByStudentMap={latestCurriculumByStudent}
                        instructor={instructor}
                        instructorName={instructorsById.get(instructor)}
                        studentAssignmentsAccessLevel={studentAssignmentsAccessLevel}
                        students={studentList}
                        dates={dates}
                        holidays={holidaysSet}
                        log={log}
                        today={today_str}
                        commitChanges={(log_id, _instructor, student_id, date, value, comment) => {
                            if (!writeable) {
                                return;
                            }
                            upsert?.mutate({
                                date,
                                value,
                                comment,
                                student_id,
                                instructional_log_entry_id: log_id,
                            });
                        }}
                        updateLog={(_instructor, _student_id, _date, _value, _comment) => {
                        }} />
                ))}
            </tbody>
        </table>
    )
}

function calculateAverages(startDate: Day, endDate: Day, studentCountByInstructor: Map<string, number>, studentInstructorMap: StudentInstructorMap | undefined, log: LogData) {
    if (!log || !studentCountByInstructor || !studentInstructorMap) {
        return undefined;
    }
    return calculateStudentAverage(startDate, endDate, studentCountByInstructor, studentInstructorMap, log);
}

export default LogTable;
