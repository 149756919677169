import { authProvider, UserClassification } from "@/auth";
import { PropsWithChildren } from "react";

type Props = {
    requiredRoles?: UserClassification[],
    requiredPredicate?: () => boolean
}

const DeniedView: React.FC = () => {
    return <div>You are not allowed to view this page</div>
}

const AdminGuard: React.FC<PropsWithChildren<Props>> = ({ children, requiredRoles, requiredPredicate }) => {
    if (!authProvider.isAuthenticated || !authProvider.userClassification) {
        return <DeniedView />
    }

    if (requiredRoles && requiredRoles.indexOf(authProvider.userClassification) === -1) {
        return <DeniedView />
    }

    if (requiredPredicate && !requiredPredicate()) {
        return <DeniedView />
    }

    return children;
};

export default AdminGuard;