import Loading from "@/components/ui/loading";
import Select from 'react-select'
import { authorizedBackendGet } from "@/lib/backend";
import { useQuery } from "@tanstack/react-query";

type Props = {
    selectedDistrictId: string | null;
    onSelect: (districtId: string) => void;
}

type DistrictWireFormat = {
    district_id: string,
    district_name: string,
}

const fetchDistricts = async (): Promise<DistrictWireFormat[]> => {
    const url = `/calendar/districts`;
    const { data } = await authorizedBackendGet<{ districts: DistrictWireFormat[] }>(url);

    return data.districts
};

const DistrictSelect: React.FC<Props> = ({ onSelect, selectedDistrictId }) => {
    const query = useQuery<DistrictWireFormat[], Error>({
        queryKey: ["districts"],
        queryFn: fetchDistricts,
    });

    if (query.isLoading) {
        return <div className="">
            <div><Loading {...query} /></div>
        </div>
    }

    if (query.isError) {
        return <div className="text-xl text-center">Error: {query.error.message}</div>
    }

    const options = query.data?.map((district) => {
        return { value: district.district_id, label: district.district_name }
    });

    const selectedDistrict = query.data?.find(district => district.district_id === selectedDistrictId);

    return (
        <Select value={selectedDistrict ? {
            value: selectedDistrict.district_id,
            label: selectedDistrict.district_name,
        } : {
            value: "",
            label: "Choose a district"
        }}
            options={options}
            styles={{
                control: (styles) => ({
                    ...styles,
                    width: 500,
                }),
                option: (styles) => ({
                    ...styles,
                    color: "black"
                })
            }}
            onChange={value => {
                if (!value?.value) return
                onSelect(value.value);
            }}
        />
    );
};

export default DistrictSelect;