import { FC } from "react";
import CoachManagementRoot from "@/components/CoachManagement";
import { useFlags } from "launchdarkly-react-client-sdk";
import NotAuthorizedWrapper from "@/components/NotAuthorizedWrapper";

const CoachManagement: FC = () => {
    const { coachManagement } = useFlags();

    return <NotAuthorizedWrapper allow={coachManagement}>
        <CoachManagementRoot />
    </NotAuthorizedWrapper>;
};

export default CoachManagement;