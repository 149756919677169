import classNames from "classnames";
import { Icons } from "../icons";
import { FC } from "react";
import { useQueryClient } from "@tanstack/react-query";

type Props = {
    isActive: boolean;
}

const SavingIndicator: FC<Props> = ({ isActive }) => {
    const queryClient = useQueryClient();
    const text = queryClient.isMutating() ? "Saving" : ""
    return <div className={classNames("fixed right-2 flex flex-row gap-2 p-4 transition-opacity bg-white bg-opacity-75")} style={{
        "opacity": isActive ? 1 : 0,
    }}>{text}<Icons.spinner className="animate-spin" /></div>
};

export default SavingIndicator;