import { FC } from "react"
import { dateWithinCurrentWeek, formatDateShort } from "../InstructionalLog/utils";
import { Day } from "@/lib/core/day";
import classNames from "classnames";

type Props = {
    dates: Day[];
}

type WeekProps = {
    date: Day;
}

const CMHeaderWeek: FC<WeekProps> = ({ date }) => {
    const formattedDate = formatDateShort(date);
    const isCurrentWeek = dateWithinCurrentWeek(date);
    return (
        <>
            <td className={classNames("text-center p-3", { "border-t-2 border-black": isCurrentWeek })}>PD<br />{`${formattedDate}`}</td>
            <td className={classNames("text-center p-3", { "border-t-2 border-black": isCurrentWeek })}>Mgmt<br />{`${formattedDate}`}</td>
            <td className={classNames("text-center p-3", { "border-t-2 border-black": isCurrentWeek })}>Other<br />{`${formattedDate}`}</td>
        </>
    )
}

const CMHeaderRow: FC<Props> = ({ dates }) => {
    return (
        <tr className="border text-sm">
            <td colSpan={2} className="align-bottom p-3">Coach</td>
            {dates.map((date) => {
                return (
                    <CMHeaderWeek key={`wk${date}`} date={date} />
                )
            })}
        </tr>
    )
}

export default CMHeaderRow;