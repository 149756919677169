import classNames from "classnames";
import useInstructorOutDialog, { OutKind } from "./UseInstructorOutDialog";
import { Button } from "../ui/button";


type Props = {

}

function message(name: string | undefined, kind: OutKind) {
    let instructorName = name ?? "this instructor";

    switch (kind) {
        case OutKind.Absent:
            return `Is ${instructorName} absent all day?`;
        case OutKind.OtherResponsibilities:
            return `Is ${instructorName} out for other responsibilities all day?`;
    }
}

const InstructorOutDialog: React.FC<Props> = () => {
    const context = useInstructorOutDialog();

    if (!context) {
        console.error("Instructor Out Dialog must be used within a InstructorOutDialogProvider");
        return null;
    }

    const { show, kind, closeDialog, instructorName } = context;

    if (!show) {
        return null;
    }

    return <div className={classNames("fixed inset-0 z-50 bg-black bg-opacity-35 flex flex-col justify-center items-center")}>
        <div className="bg-white p-8 border border-black rounded flex flex-col gap-4">
            <p>{message(instructorName, kind)}</p>
            <div className="flex flex-row justify-around"><Button variant="once" onClick={(e) => {
                e.preventDefault();
                closeDialog(true);
            }}>Yes</Button>
                <Button variant="secondary" onClick={(e) => {
                    e.preventDefault();
                    closeDialog(false);
                }}>No</Button></div>
        </div>
    </div>
}

export default InstructorOutDialog;