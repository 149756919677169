import { authorizedBackendGet, authorizedBackendPost } from '@/lib/backend';
import { Day } from '@/lib/core/day';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CMUpdateKind, UpdateValue } from '../CMRow';

type CoachManagementEntry = {
    user_id: string;
    entry_date: Day;
    pd_hours: number;
    management_hours: number;
    other_hours: number;
    comment?: string;
}

type WireFormat = Omit<CoachManagementEntry, 'entry_date'> & { entry_date: string };
type UpdateWireFormat = Omit<WireFormat, 'pd_hours' | 'management_hours' | 'other_hours'> & Partial<Record<CMUpdateKind, number>>;

// TODO: Consolidate all alias types into core
export type UserId = string;
// This has to be a string because Day is an object and that would use object identity instead of value equality
export type CoachManagementDayEntry = Map<String, CoachManagementEntry>;
export type CoachManagementEntryMap = Map<UserId, CoachManagementDayEntry>;

export const COACH_MANAGMENT_QUERY_KEY = ["coach-management"];

const convertEntriesToMap = (entries: WireFormat[]): CoachManagementEntryMap => {
    const map = new Map<UserId, Map<String, CoachManagementEntry>>();
    entries.forEach((entry) => {
        const entryDate = Day.fromString(entry.entry_date);
        const userMap = map.get(entry.user_id) ?? new Map<String, CoachManagementEntry>();
        userMap.set(entryDate.toString(), { ...entry, entry_date: entryDate });
        map.set(entry.user_id, userMap);
    });
    return map;
}

const fetchCoachManagementEntries = async (
    startDate: string,
    endDate: string
): Promise<CoachManagementEntryMap> => {
    const url = `/coach-management/${startDate}/${endDate}`;
    const { data } = await authorizedBackendGet<{ entries: WireFormat[] }>(url);
    const results = data.entries;

    return convertEntriesToMap(results);
};

type MutationEntry = {
    date: Day,
    coachId: string,
    updateValue: UpdateValue
}

export const useCoachManagementEntries = (
    startDate: Day,
    endDate: Day
) => {
    const queryClient = useQueryClient()

    const query = useQuery<CoachManagementEntryMap>({
        queryKey: COACH_MANAGMENT_QUERY_KEY.concat([startDate.toString(), endDate.toString()]),
        queryFn: () => fetchCoachManagementEntries(startDate.toString(), endDate.toString())
    })

    const upsert = useMutation({
        mutationKey: ["upsert-coach-management", startDate.toString(), endDate.toString()],
        mutationFn: async (data: MutationEntry) => {
            const url = `/coach-management/${data.coachId}/${data.date.year}/${data.date.month}/${data.date.day}/${data.updateValue.kind}`;
            const entry = { user_id: data.coachId, entry_date: data.date.toString(), [data.updateValue.kind]: data.updateValue.value };
            return await authorizedBackendPost<UpdateWireFormat>(url, entry);
        },
        onMutate: async (newEntry: MutationEntry) => {
            const key = COACH_MANAGMENT_QUERY_KEY.concat([startDate.toString(), endDate.toString()]);
            await queryClient.cancelQueries({ queryKey: key });
            const previousEntries = queryClient.getQueryData<CoachManagementEntryMap>(key);
            queryClient.setQueryData<CoachManagementEntryMap>(key, (old?: CoachManagementEntryMap) => {
                const newEntries = new Map(old);
                const coachEntries = newEntries.get(newEntry.coachId) ?? new Map<String, CoachManagementEntry>();
                const entry: CoachManagementEntry = coachEntries.get(newEntry.date.toString()) ?? { user_id: newEntry.coachId, entry_date: newEntry.date, pd_hours: 0, management_hours: 0, other_hours: 0 };
                switch (newEntry.updateValue.kind) {
                    case CMUpdateKind.PD:
                        entry.pd_hours = newEntry.updateValue.value;
                        break;
                    case CMUpdateKind.MGMT:
                        entry.management_hours = newEntry.updateValue.value;
                        break;
                    case CMUpdateKind.OTHER:
                        entry.other_hours = newEntry.updateValue.value;
                        break;
                    case CMUpdateKind.COMMENT:
                        entry.comment = newEntry.updateValue.value ?? undefined;
                        break;
                }
                coachEntries.set(newEntry.date.toString(), entry);
                newEntries.set(newEntry.coachId, coachEntries);
                return newEntries;
            });

            return { previousEntries }

        },
        onSettled: async () => {
            return await queryClient.invalidateQueries({
                queryKey: COACH_MANAGMENT_QUERY_KEY.concat([startDate.toString(), endDate.toString()])
            });
        },
        onError: (error, _newEntry, context) => {
            console.error("Error", error);
            queryClient.setQueryData<CoachManagementEntryMap>(COACH_MANAGMENT_QUERY_KEY.concat([startDate.toString(), endDate.toString()]), context?.previousEntries);
        }
    });

    return { query, upsert }
};