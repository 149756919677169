import { FC, Fragment, useCallback, useState } from "react";
import CMRow, { UpdateValue } from "./CMRow";
import classNames from "classnames";
import { advanceToMonday, dateWithinCurrentWeek, firstDayOfWeek } from "../InstructionalLog/utils";
import { Day } from "@/lib/core/day";
import CMHeaderRow from "./CMHeader";
import { Coach, useCoaches } from "@/hooks/useCoaches";
import * as Switch from "@radix-ui/react-switch"
import DateRange from "@molecules/DateRange";
import DateNavigator from "@molecules/DateNavigator";
import { Skeleton } from "@mantine/core";
import { useCoachManagementEntries } from "./hooks/useCoachManagementEntries";

type TableBodyProps = {
    skeleton: boolean;
    coaches: Coach[] | undefined;
    dates: Day[];
}

const CoachManagementTableBody: FC<TableBodyProps> = ({ skeleton: loadingCoaches, coaches, dates }) => {
    const { query: entries, upsert: saveEntry } = useCoachManagementEntries(dates[0], dates[dates.length - 1]);
    const skeleton = !entries.isSuccess || loadingCoaches;
    const skeletons = skeleton ? new Array(19).fill(0) : [];

    const onKeyPress = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            const currentRow = parseInt(e.currentTarget.dataset.row ?? "0") ?? 0;
            const currentCol = parseInt(e.currentTarget.dataset.col ?? "0") ?? 0;
            const nextRow = currentRow + 1;
            const downElement: any = document.querySelector(`input[data-row="${nextRow}"][data-col="${currentCol}"]`);
            // Normal Advance
            if (downElement) {
                downElement.focus();
                return;
            }

            // End of Instructor
            const nextThousand = Math.floor(currentRow / 1000 + 1) * 1000;
            const nextElement: any = document.querySelector(`input[data-row="${nextThousand}"][data-col="${currentCol}"]`);
            if (nextElement) {
                nextElement.focus();
                return;
            }


            // End of Column

            // We have to cycle through the columns to find the first one that isn't a holiday
            for (let nextCol = currentCol + 1; nextCol < dates.length; nextCol++) {
                const topElement: any = document.querySelector(`input[data-row="0"][data-col="${nextCol}"]`);
                if (topElement) {
                    topElement.focus();
                    return;
                }
            }

            // End of Table
        }
    }, []);

    const onChange = (date: Day, coachId: string, updateValue: UpdateValue) => {
        saveEntry.mutate({ date, coachId, updateValue });
    };

    return <tbody>
        {skeleton && skeletons.map((_, i) => <tr key={`skeleton-${i}`}>
            <td colSpan={2} className="p-3"><Skeleton height={24} visible={skeleton} /></td>
            {dates.map((date) => {
                return (
                    <Fragment key={`skeleton-${i}-${date}`}>
                        <td className="p-3 border-l border-r" colSpan={3}><Skeleton height={24} visible={skeleton} /></td>
                    </Fragment>
                );
            })}
        </tr>)}

        {!skeleton && coaches?.map((coach, i) => {
            return (
                <CMRow onKeyPress={onKeyPress} rowNumber={i} coach={coach} dates={dates} key={coach.user_id} entries={entries.data?.get(coach.user_id)} isLastRow={i === coaches.length - 1} onChange={onChange} />
            );
        })}
    </tbody>
}

const ActiveOnlyToggle: FC<{ value: boolean, onToggle: (checked: boolean) => void }> = ({ value, onToggle }) => {
    return (
        <div className="flex flex-row items-center my-6">
            <label htmlFor="activeOnlyToggle" className="pr-[15px] text-[15px] leading-none">
                Show Only Active Coaches
            </label>
            <Switch.Root checked={value} onCheckedChange={(checked) => onToggle(checked)} id="activeOnlyToggle" className="relative h-[12px] w-[21px] cursor-default rounded-full outline outline-gray-200 data-[state=checked]:bg-ponce">
                <Switch.Thumb className="block size-[10px] translate-x-0.5 rounded-full bg-white outline outline-gray-200 transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-[10px]" />
            </Switch.Root>
        </div>
    )
}

const CoachManagement: FC = () => {
    const { data: coaches, isSuccess: coachesLoaded } = useCoaches();
    const [showOnlyActive, setShowOnlyActive] = useState(true);
    const [selectedDate, setSelectedDate] = useState(advanceToMonday(new Date(), 0));

    const filteredCoaches = coaches?.filter((coach) => {
        return !showOnlyActive || coach.is_active;
    });
    const startDate = advanceToMonday(selectedDate, -7);
    const endDate = advanceToMonday(selectedDate, 7);
    const dates: Day[] = [
        Day.fromDate(startDate),
        Day.fromDate(selectedDate),
        Day.fromDate(endDate)
    ]

    const nextWeek = () => {
        setSelectedDate(advanceToMonday(selectedDate, 7));
    }

    const prevWeek = () => {
        setSelectedDate(advanceToMonday(selectedDate, -7));
    }

    return (
        <div className="p-12">
            <datalist id="one-or-zero">
                <option value="0" />
                <option value="1" />
            </datalist>
            <div className="flex flex-row gap-4 items-center">
                <DateRange startDate={selectedDate} setDate={(date) => {
                    const correctedDate = firstDayOfWeek(new Date(date));
                    // We have so advance because firstDayOfWeek is sunday, but we want to show monday
                    correctedDate.setDate(correctedDate.getDate() + 1);
                    setSelectedDate(correctedDate);
                }} />
                <div className="grow pr-2"><DateNavigator
                    prevWeek={prevWeek}
                    nextWeek={nextWeek}
                    legend={<ActiveOnlyToggle value={showOnlyActive} onToggle={setShowOnlyActive} />} /></div>
            </div>
            <table className={classNames("table-fixed", "border-collapse", "w-full", "border")}>
                <colgroup>
                    <col span={2} />{/* Coach */}
                    {dates.map((date) => {
                        const isCurrentWeek = dateWithinCurrentWeek(date);
                        return (
                            <Fragment key={`colgroup${date}`}>
                                <col className={classNames("border-l", { "border-l-2 border-black": isCurrentWeek })} />
                                <col className={classNames("")} />
                                <col className={classNames("border-r", { "border-r-2 border-black": isCurrentWeek })} />
                            </Fragment>);
                    })}
                </colgroup>
                <thead>
                    <CMHeaderRow dates={dates} />
                </thead>
                <CoachManagementTableBody skeleton={!coachesLoaded} coaches={filteredCoaches} dates={dates} />
            </table>
        </div>
    );
}

export default CoachManagement;