import { FC, useState } from "react"
import { Day } from "@/lib/core/day";
import { Coach } from "@/hooks/useCoaches";
import { dateWithinCurrentWeek } from "../InstructionalLog/utils";
import classNames from "classnames";
import { CoachManagementDayEntry } from "./hooks/useCoachManagementEntries";
import ErrorToolTip from "@atoms/ErrorToolTip";
import Comment from "@molecules/Comment";

type OnChangeCallback = (date: Day, coachId: string, updateValue: UpdateValue) => void;
type OnChangeCallbackCoachBound = (date: Day, updateValue: UpdateValue) => void;

type Props = {
    coach: Coach;
    dates: Day[];
    isLastRow: boolean;
    entries: CoachManagementDayEntry | undefined;
    rowNumber: number;
    onChange: OnChangeCallback;
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

type WeekProps = {
    date: Day;
    pd: number;
    mgmt: number;
    other: number;
    comment?: string;
    isLastRow: boolean;
    rowNumber: number;
    colNumber: number;
    onChange: OnChangeCallbackCoachBound;
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const CELL_CLASSES = "relative w-full bg-transparent p-1.5 text-sm";

export enum CMUpdateKind {
    PD = "pd",
    MGMT = "mgmt",
    OTHER = "other",
    COMMENT = "comment"
}

export type UpdateValue =
    | { kind: CMUpdateKind.PD, value: number }
    | { kind: CMUpdateKind.MGMT, value: number }
    | { kind: CMUpdateKind.OTHER, value: number }
    | { kind: CMUpdateKind.COMMENT, value: string | null }

type Values = {
    pd: number | string;
    mgmt: number | string;
    other: number | string;
}

type CMCellProps = {
    error?: string;
    initialValue: number | string;
    value: number | string;
    withBorder?: boolean;
    min: number; max: number;
    rowNumber: number;
    colNumber: number;
    setError: (error?: string) => void;
    setValue: (value: number | string) => void;
    validate: (value: number | string) => string | undefined;
    commitChange: (value: number | string) => void;
    commitComment?: (value: string | null) => void;
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    canComment?: boolean;
    comment?: string;
}

const CMEntryCell: FC<CMCellProps> = ({ initialValue, value, error, withBorder, min, max, setError, validate, setValue, commitChange, commitComment, rowNumber, colNumber, onKeyPress, canComment, comment: incomingComment }) => {
    const [enteredComment, setEnteredComment] = useState<string | null>(incomingComment ?? null);
    return <td

        className={classNames(CELL_CLASSES, { "border-b-2 border-black": Boolean(withBorder) })}>
        {error && <ErrorToolTip error={error} />}
        {canComment && <Comment
            dialogId={`CMComment-${rowNumber}-${colNumber}`}
            writable={true}
            comment={enteredComment}
            onChange={(value) => setEnteredComment(value)}
            onSubmit={(value) => commitComment?.(value)}
        />}
        <input type="number"
            min={min} max={max}
            data-row={rowNumber} data-col={colNumber}
            value={value}
            className={classNames(CELL_CLASSES, "text-center")}
            onChange={(e) => {
                const newValue = parseFloat(e.target.value);
                const updateValue = Number.isNaN(newValue) ? "" : newValue;
                const validationError = validate(updateValue);
                setError(validationError);
                setValue(updateValue);
            }}
            onBlur={() => {
                if (value === "") {
                    setValue(0);
                }
                const validationError = validate(value);
                if (validationError) {
                    setError(validationError);
                    return;
                }
                if (value !== initialValue) {
                    commitChange(value);
                }
            }}
            onKeyDown={onKeyPress}
        />
    </td>
}

const CMWeek: FC<WeekProps> = ({ date, pd, mgmt, other, isLastRow: isLastRow, onChange, rowNumber, colNumber, onKeyPress, comment }) => {
    const isCurrentWeek = dateWithinCurrentWeek(date);
    const [values, setValues] = useState<Values>({ pd, mgmt, other });
    const [errors, setErrors] = useState<{ pd?: string, mgmt?: string, other?: string }>({ pd: undefined, mgmt: undefined, other: undefined });

    return (
        <>
            <CMEntryCell
                initialValue={pd}
                value={values.pd}
                withBorder={isCurrentWeek && isLastRow}
                error={errors.pd}
                min={0} max={1}
                rowNumber={rowNumber}
                colNumber={colNumber}
                onKeyPress={onKeyPress}
                setError={(e) => setErrors((prev) => ({ ...prev, pd: e }))}
                setValue={(v) => setValues({ ...values, pd: v })}
                validate={(v) => {
                    if (v !== 1 && v !== 0 && v !== "") {
                        return "PD must be 0 or 1";
                    }
                    return undefined;
                }}
                commitChange={(v) => {
                    const qualifiedValue = v === "" ? 0 : v;
                    return onChange(date, { kind: CMUpdateKind.PD, value: qualifiedValue as number });
                }}
            />
            <CMEntryCell
                initialValue={mgmt}
                value={values.mgmt}
                withBorder={isCurrentWeek && isLastRow}
                error={errors.mgmt}
                min={0} max={1}
                rowNumber={rowNumber}
                colNumber={colNumber + 1}
                onKeyPress={onKeyPress}
                setError={(e) => setErrors((prev) => ({ ...prev, mgmt: e }))}
                setValue={(v) => setValues({ ...values, mgmt: v })}
                validate={(v) => {
                    if (v !== 1 && v !== 0 && v !== "") {
                        return "MGMT must be 0 or 1";
                    }
                    return undefined;
                }}
                commitChange={(v) => {
                    const qualifiedValue = v === "" ? 0 : v;
                    return onChange(date, { kind: CMUpdateKind.MGMT, value: qualifiedValue as number });
                }}
            />
            <CMEntryCell
                canComment={true}
                comment={comment}
                initialValue={other}
                value={values.other}
                withBorder={isCurrentWeek && isLastRow}
                error={errors.other}
                min={0.01} max={99.99}
                rowNumber={rowNumber}
                colNumber={colNumber + 2}
                onKeyPress={onKeyPress}
                setError={(e) => setErrors((prev) => ({ ...prev, other: e }))}
                setValue={(v) => setValues({ ...values, other: v })}
                validate={(v) => {
                    // Allow Empty String
                    if (v === "") {
                        return undefined;
                    }
                    const n = parseFloat(`${v}`);
                    if (isNaN(n)) {
                        return "Other must be a number";
                    }

                    if ((n) !== 0.0 && ((n) < 0.01 || (n) > 99.99)) {
                        return `Other must be a number between 0.01 and 99.99`;
                    }

                    if ((comment === undefined || comment === "") && (n) !== 0) {
                        return `Comment is required when entering a value`;
                    }
                    return undefined;
                }}
                commitChange={(v) => {
                    // Truncate to 2 decimal places
                    const qualifiedNumber = v === "" ? 0 : parseFloat(v as string);
                    console.log(qualifiedNumber, Math.trunc(qualifiedNumber), Math.trunc(qualifiedNumber * 100), Math.trunc(qualifiedNumber * 100) / 100);
                    const numberValue = Math.trunc(((v === "" ? 0 : parseFloat(v as string)) * 100)) / 100;
                    return onChange(date, { kind: CMUpdateKind.OTHER, value: numberValue });
                }}
                commitComment={(v) => {
                    if (v === "" && values.other !== 0) {
                        setValues({ ...values, other: 0 });
                        onChange(date, { kind: CMUpdateKind.OTHER, value: 0 });
                    }
                    if (errors.other) {
                        setErrors({ ...errors, other: undefined });
                        onChange(date, { kind: CMUpdateKind.OTHER, value: values.other as number });
                    }
                    return onChange(date, { kind: CMUpdateKind.COMMENT, value: v ?? "" });
                }}
            />
        </>
    )
}

const CMRow: FC<Props> = ({ dates, coach, entries, isLastRow, onChange, rowNumber, onKeyPress }) => {
    const boundOnChange = (date: Day, updateValue: UpdateValue) => {
        onChange(date, coach.user_id, updateValue);
    }

    return (
        <tr className="border">
            <td className="p-3 border" colSpan={2}>{coach.name}</td>
            {dates.map((date, idx) => {
                const entry = entries?.get(date.toString());
                const pd = entry?.pd_hours ?? 0;
                const mgmt = entry?.management_hours ?? 0;
                const other = entry?.other_hours ?? 0;
                return (
                    <CMWeek
                        rowNumber={rowNumber}
                        colNumber={idx * 3}
                        key={`wk${date}`}
                        date={date}
                        pd={pd} mgmt={mgmt} other={other}
                        comment={entry?.comment}
                        isLastRow={isLastRow}
                        onChange={boundOnChange}
                        onKeyPress={onKeyPress}
                    />
                )
            })}
        </tr>
    )
}

export default CMRow;