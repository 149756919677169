import { Day } from "@/lib/core/day";
import { createContext, PropsWithChildren, useCallback, useContext, useState } from "react";


export enum OutKind {
    Absent,
    OtherResponsibilities
}
type Committer = () => void;

type InstructorOutData = {
    show: boolean;
    day: Day | undefined;
    instructor: string | undefined;
    kind: OutKind;
    instructorName: string | undefined;
    committer: Committer | undefined;
}



export type InstructorOutDialogContextType = InstructorOutData & {
    openDialog: (day: Day, instructor: string, instructorName: string | undefined, kind: OutKind, committer: Committer) => void;
    closeDialog: (v: boolean) => void;
}

const InstructorOutDialogContext = createContext<InstructorOutDialogContextType | null>(null);
export const InstructorOutDialogProvider = InstructorOutDialogContext.Provider;

function useInstructorOutDialog(): InstructorOutDialogContextType | null {
    const instructorOutDialogContext: InstructorOutDialogContextType | null = useContext(InstructorOutDialogContext);

    return instructorOutDialogContext
}

export const InstructorDialogProvider: React.FC<PropsWithChildren<{ onSubmit: (day: Day, id: string, v: boolean, kind: OutKind, committer: Committer) => void }>> = ({ children, onSubmit }) => {
    const [dialogData, setDialogData] = useState<InstructorOutData>({ show: false, day: undefined, instructor: undefined, kind: OutKind.Absent, instructorName: undefined, committer: undefined });

    const openDialog = (day: Day, instructor: string, instructorName: string | undefined, kind: OutKind, committer: Committer) => {
        setDialogData({ show: true, instructor, kind, instructorName, day, committer });
    }

    const closeDialog = useCallback((v: boolean) => {
        if (dialogData.instructor && dialogData.day && dialogData.committer) {
            onSubmit(dialogData.day, dialogData.instructor, v, dialogData.kind, dialogData.committer);
        } else {
            console.error("Instructor, committer or day is not set", dialogData);
        }
        setDialogData({ show: false, instructor: undefined, kind: OutKind.Absent, instructorName: undefined, day: undefined, committer: undefined });
    }, [onSubmit, dialogData.instructor]);

    return (
        <InstructorOutDialogProvider value={{ ...dialogData, openDialog, closeDialog }}>
            {children}
        </InstructorOutDialogProvider>
    )

};

export default useInstructorOutDialog;