import { useCallback, useState } from "react";
import { Day } from "@/lib/core/day";
import { InstructionalLogEntry } from "@/hooks/useInstructionalLog";
import { Link } from "react-router-dom";
import LogCell from "./LogCell";
import InstructorAssignment from "./StudentAssignment/InstructorAssignment";

type Props = {
    rowNumber: number,
    instructor: string,
    student: string,
    student_id: string,
    instructor_id: string,
    dates: Day[],
    holidays: Set<string>,
    log: Map<string, InstructionalLogEntry> | undefined,
    updateLog?: (date: string, log_id: string | undefined, value: string, comment?: string) => void,
    commitChanges?: (date: string, log_id: string | undefined, value: string, comment?: string) => void,
    maxCurriculum?: string,
    editable: boolean,
    smaller?: boolean,
    today: string,
    studentAssignmentsAccessLevel: string | undefined;
}


const BASE_CELL_CLASSES = "text-center relative border";
const LABEL_CELL_CLASSES = 'text-left relative border px-1.5';
export const CELL_CLASSES = `${BASE_CELL_CLASSES}`;
export const TODAY_CELL_CLASSES = `${BASE_CELL_CLASSES}`;
const SMALLER_CLASSES = "p-1 text-text border-bottom text-xs";

type Validation = (value: string) => string | undefined | null;
const validations: Validation[] = [
    (value: string) => value.length == 1 ? ["s", "i", "o"].includes(value) ? undefined : "only s, i, o codes are allowed" : null,
    (value: string) => /^\d{1,3}(\.\d*?){0,1}$/.test(value) ? undefined : "invalid curriculum code",
]

const validate = (value: string): string | undefined => {
    let results = validations.map(v => v(value))
    let anyPassed = results.some(r => r === undefined);
    return anyPassed ? undefined : results.find(r => Boolean(r)) ?? undefined
}

const LogRow: React.FC<Props> = ({ today, rowNumber, instructor, instructor_id, student, student_id, log, dates, editable, smaller, updateLog, commitChanges, maxCurriculum, holidays, studentAssignmentsAccessLevel }) => {
    const cellClasses = smaller ? SMALLER_CLASSES : CELL_CLASSES;
    const [validationErrors, setValidationErrors] = useState<Map<string, string>>(new Map());
    const onChange = useCallback((id: string, log_id: string | undefined, value: string, comment?: string) => {
        updateLog?.(id, log_id, value, comment);
    }, [log]);

    const onKeyPress = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            const currentRow = parseInt(e.currentTarget.dataset.row ?? "0") ?? 0;
            const currentCol = parseInt(e.currentTarget.dataset.col ?? "0") ?? 0;
            const nextRow = currentRow + 1;
            const downElement: any = document.querySelector(`input[data-row="${nextRow}"][data-col="${currentCol}"]`);
            // Normal Advance
            if (downElement) {
                downElement.focus();
                return;
            }

            // End of Instructor
            const nextThousand = Math.floor(currentRow / 1000 + 1) * 1000;
            const nextElement: any = document.querySelector(`input[data-row="${nextThousand}"][data-col="${currentCol}"]`);
            if (nextElement) {
                nextElement.focus();
                return;
            }


            // End of Column

            // We have to cycle through the columns to find the first one that isn't a holiday
            for (let nextCol = currentCol + 1; nextCol < dates.length; nextCol++) {
                const topElement: any = document.querySelector(`input[data-row="0"][data-col="${nextCol}"]`);
                if (topElement) {
                    topElement.focus();
                    return;
                }
            }

            // End of Table
        }
    }, []);

    const onBlur = useCallback((id: string, log_id: string | undefined, value: string, comment?: string) => {
        let error = value.length > 0 ? validate(value) : "";
        setValidationErrors((prev) => {
            if (error) {
                prev.set(id, error)
            } else {
                prev.delete(id);
            }
            return new Map(prev)
        });
        if (!error) {
            commitChanges?.(id, log_id, value, comment);
        }
    }, [log, validationErrors]);

    const student_url = `/?student_id=${student_id}${maxCurriculum ? `&cycle=${maxCurriculum}` : ""}`;

    return (
        <tr>
            <td className={`p-1 text-sm ${LABEL_CELL_CLASSES} overflow-hidden text-nowrap`} colSpan={2}>
                <InstructorAssignment
                    instructorId={instructor_id ?? "unknown"}
                    studentId={student_id}
                    instructorName={instructor}
                    canAssignInstructor={Boolean(studentAssignmentsAccessLevel)} />
            </td>
            <td className={`w-fit max-w-fit py-1.5 px-0.5 text-sm ${LABEL_CELL_CLASSES} overflow-hidden`}>{maxCurriculum ?? "-"}</td>
            <td className={`p-1 ${LABEL_CELL_CLASSES} text-sm overflow-hidden text-nowrap`} colSpan={2}><Link className="underline text-ponce" to={student_url}>{student}</Link></td>
            {dates.map((date, idx) => {
                const ds = date.toString();
                const classes = ds === today ? TODAY_CELL_CLASSES : cellClasses;
                if (holidays.has(ds)) {
                    return <td data-holiday data-row={rowNumber} data-col={idx} className={classes} key={ds}></td>;
                }
                const logEntry = log?.get(ds);

                return <td className={classes} key={`${ds}-${student_id}-${logEntry?.value}`}>
                    <LogCell rowNumber={rowNumber} colNumber={idx}
                        value={logEntry?.value}
                        log_id={logEntry?.instructional_log_entry_id}
                        comment={logEntry?.comment}
                        id={ds} validationError={validationErrors.get(ds)}
                        student_id={student_id}
                        onKeyPress={editable ? onKeyPress : undefined}
                        onChange={editable ? onChange : undefined}
                        onBlur={onBlur} />
                </td>
            })}
        </tr>
    )
}


export default LogRow;