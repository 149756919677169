import classNames from "classnames";
import Dialog, { openDialog } from "../Dialog";
import { Button } from "../ui/button";

type Props = {
    dialogId: string;
    writable: boolean;
    comment: string | null;
    onChange?: (value: string | null) => void;
    onSubmit: (value: string | null) => void;
}

const Comment: React.FC<Props> = ({ writable, comment, dialogId, onChange: onChange, onSubmit }) => {
    const onCommit = (_event: any) => {
        onSubmit?.(comment);
    }

    const onInputChange = (ev: any) => {
        onChange?.(ev.target.value);
    };
    if (writable) {
        const hasComment = Boolean(comment);
        return (
            <>
                <Dialog id={dialogId} title="Add Note" showCloseButton={false} onEscape={() => onCommit(null)}>
                    <form method="dialog" onSubmit={onCommit}>
                        <div className="flex flex-row gap-4 items-center">
                            <input className="py-1 px-3" type="text"
                                onChange={onInputChange}
                                value={comment ?? undefined}
                                placeholder="Note" maxLength={140} autoFocus />
                            <Button variant="once" size="sm" type="submit">Save</Button>
                        </div>
                    </form>
                </Dialog >
                <button className={classNames("absolute top-0 right-0 focus:ring-0 focus:ring-offset-0 focus-visible:ring-0 focus-visible:ring-offset-0", { "text-ponce": hasComment }, { "text-gray-300": !hasComment })} tabIndex={-1} onClick={_ => openDialog(dialogId)}>
                    <svg className="focus:ring-0 focus:ring-offset-0 focus-visible:ring-0 focus-visible:ring-offset-0" height="10" width="10"><polygon points="0,0 10,10 10,0" fill="currentColor" /></svg>
                </button>
            </>
        )
    } else if (comment) {
        return <>
            <Dialog id={dialogId} title="Note" showCloseButton={false}>
                <p>{comment}</p>
            </Dialog>
            <button className="absolute top-0 right-0 text-ponce" onClick={_ => openDialog(dialogId)}>
                <svg height="10" width="10"><polygon points="0,0 10,10 10,0" fill="currentColor" /></svg>
            </button>
        </>
    } else {
        return <button className="absolute top-0 right-0 text-gray-300" tabIndex={-1}>
            <svg height="10" width="10"><polygon points="0,0 10,10 10,0" fill="currentColor" /></svg>
        </button>
    }
}

export default Comment;