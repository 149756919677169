import { authProvider } from "@/auth";
import { Icons } from "@/components/icons";
import { COACH_MEETING_REPORT_DASHBOARD_ID_COACH, COACH_MEETING_REPORT_DASHBOARD_ID_EMPLOYEE } from "@/consts";
import { authorizedBackendPost } from "@/lib/backend";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";

const CoachMeetingReport: React.FC = () => {
    const [jwt, setJwt] = useState<string | null>(null);
    const { coachDataTab } = useFlags();
    let coachDataTabReleaseDate = new Date("2025-01-20");
    let now = new Date();
    let userCanViewCoachDataTab = now >= coachDataTabReleaseDate && coachDataTab && authProvider.isCoach();

    useEffect(() => {
        async function fetchExploJwt() {
            const body = {
                "provided_id": authProvider.email,
                "embed_id": authProvider.isEmployee() ? COACH_MEETING_REPORT_DASHBOARD_ID_EMPLOYEE : authProvider.isCoach() ? COACH_MEETING_REPORT_DASHBOARD_ID_COACH : ""
            }

            const results = await authorizedBackendPost("/api/generate_explo_jwt",
                body
            )
            setJwt(results.data.jwt)
        }
        fetchExploJwt()
    }, [])

    if (!userCanViewCoachDataTab && !authProvider.isEmployee()) {
        return <div>Unauthorized</div>
    }

    if (!jwt) {
        return <Icons.spinner className="m-auto h-4 w-4 animate-spin" />
    }


    return (
        <div className="w-full">
            <explo-dashboard
                dash-jwt={jwt}
                isProduction={true}
                environment="production"
                refresh-minutes={10}
                style={{ height: "100vh", width: "100vw" }}
            >
            </explo-dashboard>
        </div >
    )
};

export default CoachMeetingReport;

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'explo-dashboard': any;
        }
    }
}