import { authorizedBackendGet } from "./lib/backend";

export type AuthProvider = AuthUser & {
  signin(): Promise<void>;
  isCoach(): boolean;
  isEmployee(): boolean;
  isDistrictAdmin(): boolean;
  canAccessInstructionalLog(): boolean;
};

type AuthUser = {
  isAuthenticated: boolean;
  username: string | null;
  email: string | null;
  pictureUrl: string | null;
  instructionalLogUrl: string | null;
  userClassification: UserClassification | null;
  studentDataEmbedId: string | null;
};

export enum UserClassificationValues {
  ONCE_EMPLOYEES_NO_PII = "Once Employee (No PII)",
  ONCE_COACH_MANAGER = "Once Coach Manager",
  DISTRICT_SCHOOL_STAFF = "District or School-based Staff",
  ONCE_EVALUATOR = "Once Evaluator",
  ONCE_COACH = "Once Coach",
  ONCE_EMPLOYEES = "Once Employee",
  DIRECT_TO_CONSUMER_CUSTOMER = "Direct to Consumer Customer",
}


export type UserClassification =
  | UserClassificationValues.ONCE_EMPLOYEES_NO_PII
  | UserClassificationValues.ONCE_COACH_MANAGER
  | UserClassificationValues.DISTRICT_SCHOOL_STAFF
  | UserClassificationValues.ONCE_EVALUATOR
  | UserClassificationValues.ONCE_COACH
  | UserClassificationValues.ONCE_EMPLOYEES
  | UserClassificationValues.DIRECT_TO_CONSUMER_CUSTOMER;

// Used to determine if they have access to the instructor scoring and coaching meeting summary views
const coachClassifications = new Set([
  UserClassificationValues.ONCE_COACH,
  UserClassificationValues.ONCE_EMPLOYEES,
])

// Used to determine if they have access to the instructional log
const instructionalLogClassifications = new Set([
  UserClassificationValues.ONCE_EVALUATOR,
  UserClassificationValues.ONCE_COACH,
  UserClassificationValues.ONCE_COACH_MANAGER,
  UserClassificationValues.ONCE_EMPLOYEES,
  UserClassificationValues.ONCE_EMPLOYEES_NO_PII,
])

// Used to determine if they have access to district admin features
const districtAdminClassifications = new Set([
  UserClassificationValues.ONCE_EMPLOYEES,
])

export const authProvider: AuthProvider = {
  isAuthenticated: false,
  username: null,
  pictureUrl: null,
  email: null,
  instructionalLogUrl: null,
  userClassification: null,
  studentDataEmbedId: null,
  isCoach: () => { return coachClassifications.has(authProvider.userClassification as UserClassificationValues) },
  isEmployee: () => { return authProvider.userClassification === UserClassificationValues.ONCE_EMPLOYEES },
  isDistrictAdmin: () => { return districtAdminClassifications.has(authProvider.userClassification as UserClassificationValues) },
  canAccessInstructionalLog: () => { return instructionalLogClassifications.has(authProvider.userClassification as UserClassificationValues) },
  async signin() {
    let result = new Promise<AuthUser>(function (resolve) {
      if (localStorage.getItem("JWT") == null) {
        console.log("No JWT found");
        resolve({
          isAuthenticated: false,
          username: null,
          email: null,
          pictureUrl: null,
          instructionalLogUrl: null,
          userClassification: null,
          studentDataEmbedId: null,
        });
      } else {
        console.log("JWT found");
        authorizedBackendGet<{
          name?: string;
          picture?: string;
          email?: string;
          instructional_log?: string;
          user_classification?: UserClassification;
          student_data_embed_id?: string
        }>("/me")
          .then((res) => {
            resolve({
              isAuthenticated: true,
              username: res.data.name || null,
              pictureUrl: res.data.picture || null,
              email: res.data.email || null,
              instructionalLogUrl: res.data.instructional_log || null,
              userClassification: res.data.user_classification || null,
              studentDataEmbedId: res.data.student_data_embed_id || null
            });
          })
          .catch((err) => {
            console.log("Error while checking jwt: " + err);
            localStorage.removeItem("JWT");
            resolve({
              isAuthenticated: false,
              username: null,
              pictureUrl: null,
              email: null,
              instructionalLogUrl: null,
              userClassification: null,
              studentDataEmbedId: null,
            });
          });
      }
    });
    let credentials: AuthUser = await result;
    authProvider.isAuthenticated = credentials.isAuthenticated;
    authProvider.username = credentials.username;
    authProvider.pictureUrl = credentials.pictureUrl;
    authProvider.email = credentials.email;
    authProvider.instructionalLogUrl = credentials.instructionalLogUrl;
    authProvider.userClassification = credentials.userClassification;
    authProvider.studentDataEmbedId = credentials.studentDataEmbedId;
  },
};
