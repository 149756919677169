import { PropsWithChildren } from "react";

type Props = {
    allow: boolean;
}

const NotAuthorizedWrapper: React.FC<PropsWithChildren<Props>> = ({ allow, children }) => {
    if (!allow) {
        // TODO: Make this more interesting and user friendly
        return <div>Unauthorized</div>
    }

    return <>{children}</>
}

export default NotAuthorizedWrapper;