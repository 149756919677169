import { authorizedBackendGet } from "@/lib/backend"
import { useQuery } from "@tanstack/react-query"

type SchoolCapabilities = {
    "SCHOOL": string[]
    "DISTRICT": undefined
}
type DistrictCapabilities = {
    "SCHOOL": undefined
    "DISTRICT": string[]
}
type FullCapabilities = "FULL"
type Capabilities = {
    capabilities: {
        STUDENT_ASSIGNMENTS: SchoolCapabilities | DistrictCapabilities | FullCapabilities | undefined
    }
}


const CAP_QUERY_KEY = ["student_assignments_capabilities"]

const studentAssignmentsCapabilities = async (school_id: string): Promise<Capabilities> => {
    const url = `/student_assignments/capabilities/${school_id}`
    const { data } = await authorizedBackendGet<Capabilities>(url)
    return data
}

export const useStudentAssignmentsCapabilities = (school_id?: string) => {
    const query = useQuery<Capabilities, Error>({
        queryKey: CAP_QUERY_KEY.concat([school_id ?? ""]),
        queryFn: () => studentAssignmentsCapabilities(school_id ?? ""),
        enabled: !!school_id
    })

    return query
}

export enum StudentAssignmentAccessLevel {
    FULL = "FULL",
    SCHOOL = "SCHOOL",
    DISTRICT = "DISTRICT",
}

export const accessLevelForStudentAssignments = (data?: Capabilities, district_id?: string, school_id?: string): StudentAssignmentAccessLevel | undefined => {
    if (!data || !data.capabilities) {
        return undefined;
    }

    if (data.capabilities.STUDENT_ASSIGNMENTS === "FULL") {
        return StudentAssignmentAccessLevel.FULL;
    }

    if (data.capabilities.STUDENT_ASSIGNMENTS === undefined) {
        return undefined;
    }

    if (district_id && data.capabilities.STUDENT_ASSIGNMENTS.DISTRICT) {
        return (data.capabilities.STUDENT_ASSIGNMENTS as any).DISTRICT.includes(district_id) ? StudentAssignmentAccessLevel.DISTRICT : undefined;
    }

    if (school_id && data.capabilities.STUDENT_ASSIGNMENTS.SCHOOL) {
        return (data.capabilities.STUDENT_ASSIGNMENTS as any).SCHOOL.includes(school_id) ? StudentAssignmentAccessLevel.SCHOOL : undefined;
    }

    return undefined;
};

export const useStudentAssignments = (school_id: string) => {
    useStudentAssignmentsCapabilities(school_id)
    // const queryClient = useQueryClient()
}