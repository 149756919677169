import { Day } from "@/lib/core/day";
import { FC, useState } from "react";
import { DatePickerInput, DatesRangeValue } from '@mantine/dates';

type Props = {
    allowedRange: [Date, Date],
    onSubmit: (dateRange: [Day, Day]) => void
}

const AddVacation: FC<Props> = ({ allowedRange, onSubmit }) => {
    const [selectedValue, setSelectedValue] = useState<DatesRangeValue>();

    return (<div className="my-8 px-4 mx-2 flex flex-row gap-4 items-center border text-black rounded">
        <DatePickerInput
            placeholder="Select a new vacation date range"
            type="range"
            valueFormat="YYYY-MM-DD"
            allowSingleDateInRange
            value={selectedValue}
            onChange={newValue => {
                setSelectedValue(newValue);
            }}
            excludeDate={date => {
                console.log(date, allowedRange[0], allowedRange[1]);
                return date < allowedRange[0] || date > allowedRange[1];
            }}
            defaultDate={allowedRange[0]}
        />
        <button className="text-2xl" onClick={() => {
            if (!selectedValue) { return }
            if (selectedValue[0]) {
                const startDay = Day.fromDate(selectedValue[0]);
                const endDay = selectedValue[1] ? Day.fromDate(selectedValue[1]) : startDay;
                onSubmit([startDay, endDay]);
                setSelectedValue([null, null]);
            }
        }}>⊕</button>
    </div>)
};

export default AddVacation;