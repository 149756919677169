import { useToast } from "@/components/ui/use-toast";
import DailyIframe, { DailyCall } from '@daily-co/daily-js';
import { DailyAudio, DailyProvider } from '@daily-co/daily-react';
import Axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { authProvider } from "../auth";
import HairCheck from '../components/HairCheck/HairCheck';
import Tray from '../components/Tray/Tray';
import { Content } from '../components/Content';
import { ActionFunction, createSearchParams, useLoaderData, useNavigate, useSubmit } from "react-router-dom";
import ShareScreenBoundary from "../components/ScreenShareBoundary";
import { useCycles } from "@/hooks/useCycles";
import CycleChooser from "../components/CycleChooser";
import { findValidCycle } from "@/lib/findValidCycle";
import { SearchParams } from "@/main";
import searchParamsToObject from "@/lib/searchparamsToObject";
import { Icons } from "@/components/icons";
import { useFlags } from "launchdarkly-react-client-sdk";

enum CallState {
  IDLE = "IDLE",
  JOINING = "JOINING",
  JOINED = "JOINED",
  LEAVING = "LEAVING",
  ERROR = "ERROR",
  HAIRCHECK = "HAIRCHECK",
}

type RoomApiResponse = {
  url: string,
  name: string
}

type SessionLoaderData = {
  room: RoomApiResponse,
  callObject: DailyCall
}

/**
 * Posts session metadata to the server.
 */
export const sessionAction: ActionFunction = async ({ request }) => {
  let formData = await request.formData();

  const body = {
    email: formData.get("email"),
    cycle_number: formData.get("cycle_number"),
    student_id: formData.get("student_id"),
    room_name: formData.get("room_name"),
  }

  await Axios.post(`${import.meta.env.VITE_BACKEND_URL}/session`,
    body,
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('JWT')}`
      },
    }
  )
  return body
}

export async function sessionLoader(): Promise<SessionLoaderData> {
  const roomResponse = await Axios.get<RoomApiResponse>(`${import.meta.env.VITE_BACKEND_URL}/room`, {
    headers: {
      "Authorization": `Bearer ${localStorage.getItem('JWT')}`
    },
  })

  const callObject = DailyIframe.createCallObject();

  return {
    room: roomResponse.data,
    callObject,
  }
}


export type State = {
  callState: CallState,
  isFullScreen: boolean,
}

function DailySession() {
  const navigate = useNavigate();
  const { instructionalLog } = useFlags();

  const { toast } = useToast()

  const { room, callObject } = useLoaderData() as SessionLoaderData;

  const searchParams = new URLSearchParams(window.location.search);

  const studentIdFromParams = searchParams.get(SearchParams.StudentId)
  const cycleIdFromParams = searchParams.get(SearchParams.Cycle)

  const { data: cycles = [], isLoading } = useCycles()
  const cycleId = findValidCycle(cycleIdFromParams, cycles);
  const cycle = cycles.find(c => c.cycle === cycleId)

  /**
 * Navigate to a valid cycle if an invalid one is provided in the url.
 */
  useEffect(() => {
    // If the cycle from params is invalid we navigate the user to a valid cycle number
    if (cycleId !== cycleIdFromParams && !isLoading) {
      navigate({
        pathname: "/",
        search: createSearchParams({
          ...searchParamsToObject(searchParams.toString()),
          [SearchParams.Cycle]: cycleId
        }).toString()
      })
    }
  }, [cycleId, cycleIdFromParams, isLoading])

  let submitMetaData = useSubmit();
  useEffect(() => {
    if (!cycle) return;
    submitMetaData({
      email: authProvider.email,
      cycle_number: cycle.cycle,
      student_id: studentIdFromParams,
      room_name: room.name,
    }, {
      method: "post",
      navigate: false,
    })
  }, [cycle?.cycle])

  useEffect(() => {
    let timeout: any = undefined;
    if (!studentIdFromParams) {
      timeout = setTimeout(() => {
        if (instructionalLog) {
          window.location.href = "/instructional-log";
        } else {
          window.location.href = "/student-data";
        }
      }, 500);
      return () => { if (timeout) { clearTimeout(timeout) } };
    }
  }, [studentIdFromParams, instructionalLog])

  const [state, setState] = useState<State>({
    callState: CallState.IDLE,
    isFullScreen: false,
  });

  /**
   * room has been created, now we can start the hair check.
   * this gets called after component mounts via useEffect.
   */
  const startHairCheck = async () => {
    await callObject.preAuth({ url: room.url })
    await callObject.startCamera();
  }

  // this is being used to start the hair check
  // without the user needing to click a button
  useEffect(() => {
    startHairCheck().then(() => {
      setState(prev => ({
        ...prev,
        callState: CallState.HAIRCHECK
      }))
    }).catch(() => {
      setState(prev => ({
        ...prev,
        callState: CallState.ERROR
      }))
    });
  }, []);

  /**
   * Once we pass the hair check, we can actually join the call.
   * We'll pass the username entered during Haircheck to .join().
   */
  const joinCall = useCallback((userName: string) => {
    callObject?.join({ url: room.url, userName });

    // Prevent video from being recorded and transferred to a 
    // Google Drive folder while developing.
    if (import.meta.env.MODE !== "development") {
      callObject?.startRecording({
        // https://www.daily.co/tools/vcs-simulator/daily_baseline.html
        layout: {
          preset: "custom", // required even in updates
          composition_params: {
            mode: "dominant",
            "videoSettings.preferScreenshare": true,
            "videoSettings.dominant.position": "left",
            "videoSettings.dominant.splitPos": 0.7, // percent of screen split
            "videoSettings.dominant.numChiclets": 3, // height of right sidebar
          },
        },
        videoBitrate: 3000, // 720p
        maxDuration: 1200, // 20 minutes
      });
    }
    showStartRecordingToast();
    setState(prev => ({
      ...prev,
      callState: CallState.JOINED
    }))
  }, [callObject, room.url]);

  const showStartRecordingToast = async () => {
    toast({
      title: "Recording started",
      description: "Your webcam and screen are now being recorded",
    });
  }

  /**
   * Start leaving the current call.
   */
  const startLeavingCall = useCallback(() => {
    // stop recording
    callObject?.stopRecording();

    /* This will trigger a `left-meeting` event, which in turn will trigger
    the full clean-up as seen in handleNewMeetingState() below. */
    setState(prev => ({
      ...prev,
      callState: CallState.LEAVING
    }));
    callObject?.leave();

    const searchParams = new URLSearchParams(location.search);
    navigate("/session-finished?" + searchParams.toString());
  }, []);

  /**
   * Show the call UI if we're either joining, already joined, or have encountered
   * an error that is _not_ a room API error.
   */
  const showCall = [CallState.JOINING, CallState.JOINED, CallState.ERROR].includes(state.callState);

  /* When there's no problems creating the room and startHairCheck() has been successfully called,
  * we can show the hair check UI. */
  const showHairCheck = state.callState === CallState.HAIRCHECK;

  useEffect(() => {
    return () => {
      // Prevent duplicate call objects by destroying the old one when we 
      // navigate away from this route.
      callObject?.destroy();
    }
  }, [])

  if (!studentIdFromParams) {
    return null;
  }


  if (isLoading) {
    return <Icons.spinner className="m-auto h-4 w-4 animate-spin" />
  }

  return (
    <div className="px-4 py-2">
      <div className="text-lg mb-4 flex items-center gap-2">
        Session Cycle
        <CycleChooser
          cycles={cycles}
          onChange={(cycleId: string) => {
            navigate({
              pathname: window.location.pathname,
              search: createSearchParams({
                ...searchParamsToObject(searchParams.toString()),
                [SearchParams.Cycle]: cycleId
              }).toString()
            })
          }}
          value={cycleId} />
      </div>
      <DailyProvider callObject={callObject}>
        {showHairCheck && <HairCheck joinCall={joinCall} />}
        {showCall && <>
          <Tray leaveCall={startLeavingCall} />
          <ShareScreenBoundary>
            {cycle && <Content
              leaveCall={startLeavingCall}
              slidesLink={cycle.google_file_id}
              position={cycle.slide_number}
              isFullScreen={state.isFullScreen}
              setIsFullScreen={isFullScreen => setState(prev => ({
                ...prev,
                isFullScreen
              }))}
            />}
          </ShareScreenBoundary>
          <DailyAudio />
        </>}
      </DailyProvider>
    </div>
  );
}

export default DailySession;
