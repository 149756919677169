import Loading from "@/components/ui/loading";
import Select from 'react-select'
import { authorizedBackendGet } from "@/lib/backend";
import { useQuery } from "@tanstack/react-query";

type Props = {
    districtId: string | null;
    selectedYear: SchoolYearRange | null;
    onSelect: (school_year_range: SchoolYearRange | null) => void;
}

export type SchoolYearRange = {
    district_id: string,
    school_year: number,
    start_date: Date,
    end_date: Date,
}

function label(schoolYear: SchoolYearRange): string {
    const start_date = new Date(schoolYear.start_date).toLocaleDateString();
    const end_date = new Date(schoolYear.end_date).toLocaleDateString();
    return `${schoolYear.school_year} (${start_date} - ${end_date})`
}

const fetchSchoolYears = async (district_id: string): Promise<SchoolYearRange[]> => {
    const url = `/calendar/school_years/${district_id}`;
    const { data } = await authorizedBackendGet<{ school_years: SchoolYearRange[] }>(url);

    return data.school_years.map(r => {
        const start_date = new Date(r.start_date);
        start_date.setHours(0, 0, 0, 0);
        const end_date = new Date(r.end_date);
        end_date.setHours(23, 59, 59, 999);
        return {
            ...r,
            start_date,
            end_date,
        }
    })
};

const SchoolYearSelect: React.FC<Props> = ({ onSelect, districtId, selectedYear }) => {
    const query = useQuery<SchoolYearRange[], Error>({
        queryKey: ["school_years", districtId || ""],
        queryFn: async () => fetchSchoolYears(districtId!),
        enabled: !!districtId,
    });

    if (query.isLoading) {
        return <div className="">
            <div><Loading {...query} /></div>
        </div>
    }

    if (query.isError) {
        return <div className="text-xl text-center">Error: {query.error.message}</div>
    }

    const options = query.data?.map((entry) => {
        return { value: `${entry.school_year}`, label: label(entry) }
    });

    if (!options) {
        return null
    }

    return (
        <Select value={selectedYear ? {
            value: selectedYear.school_year,
            label: label(selectedYear),
        } : {
            value: "",
            label: "Choose a School Year"
        }}
            options={options}
            styles={{
                control: (styles) => ({
                    ...styles,
                    width: 300,
                }),
                option: (styles) => ({
                    ...styles,
                    color: "black"
                })
            }}
            onChange={value => {
                if (!value?.value) return
                const data = query.data?.find(entry => `${entry.school_year}` === value.value) || null
                onSelect(data);
            }}
        />
    );
};

export default SchoolYearSelect;