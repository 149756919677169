import { createContext, PropsWithChildren, useContext, useState } from "react";

type HardRefreshOverlayContextType = {
    startOverlay: () => void;
    endOverlay: () => void;
}

const HardRefreshOverlayContext = createContext<HardRefreshOverlayContextType | null>(null);
const Provider = HardRefreshOverlayContext.Provider;

export const HardRefreshOverlay: React.FC<PropsWithChildren> = ({ children }) => {
    const [showing, setShowing] = useState(false);

    const startOverlay = () => {
        setShowing(true);
    }

    const endOverlay = () => {
        setShowing(false);
    }

    return (
        <Provider value={{ startOverlay, endOverlay }}>
            {showing && <div className="fixed bg-black bg-opacity-50 z-50 top-0 left-0 w-screen h-screen overflow-y-scroll"></div>}
            {children}
        </Provider>
    );
}

export const useHardRefreshOverlay = () => {
    const context = useContext(HardRefreshOverlayContext);
    if (!context) {
        throw new Error("useHardRefreshOverlay must be used within a HardRefreshOverlay");
    }
    return context;
}