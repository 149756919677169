import Axios from "axios";

function headers() {
    const common_headers = {
        Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    }
    const impersonation = localStorage.getItem("impersonation");
    if (impersonation) {
        const impersonationData = JSON.parse(impersonation);
        if (impersonationData.hash) {
            return {
                ...common_headers,
                "x-impersonation-token": impersonationData.hash
            };
        } else {
            return common_headers;
        }
    } else {
        return common_headers;
    }
}

export function backendUrl(path: string) {
    return `${import.meta.env.VITE_BACKEND_URL}${path}`;
}

export async function unauthorizedGet<T>(path: string, params?: any) {
    return await Axios.get<T>(backendUrl(path), { params });
}

export async function authorizedBackendPost<T>(path: string, body: T) {
    const results = await Axios.post(
        backendUrl(path),
        body,
        {
            headers: headers()
        }
    );
    return results;
}

export async function authorizedBackendGet<T>(path: string, params?: any) {
    return await Axios.get<T>(backendUrl(path), {
        headers: headers(),
        params
    });
}

export async function authorizedBackendPut<T>(path: string, body: T) {
    return await Axios.put(
        backendUrl(path),
        body,
        {
            headers: headers()
        }
    );
}

export async function authorizedBackendDelete(path: string) {
    return await Axios.delete(
        backendUrl(path),
        {
            headers: headers()
        }
    );
}