import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { authorizedBackendGet, authorizedBackendPost } from "@/lib/backend";
import { useState } from "react";
import { INSTRUCTIONAL_LOG_REFETCH_SETTINGS } from "@/consts";
import { useHardRefreshOverlay } from "./useHardRefreshOverlay";

export type SchoolAssociation = {
    instructor_id: string
    student_id: string
    instructor_name: string
    student_name: string
    school_id: string
    school_short_name: string
    start_date: string
    end_date: string
    district_id: string
    district_name: string
    assignment_start_date: string
    assignment_end_date: string
    is_assigned: boolean
}

export type SchoolAssociationOutput = {
    students: InstructorStudentMap;
}

type InstructorId = string;
type StudentId = string;
export type InstructorStudentMap = Map<InstructorId, Map<StudentId, SchoolAssociation>>;
export type StudentInstructorMap = Map<StudentId, Set<InstructorId>>;
type AssignmentEntry = {
    instructor_id: string,
    start_date: Date,
    active_assignment: boolean,
    association: SchoolAssociation
}

const STUDENT_QUERY_KEY = ["school_associations", "students"];
const INSTRUCTOR_QUERY_KEY = ["school_associations", "instructors"];
const UNASSIGNED_INSTRUCTOR_ID = "<<unassigned>>";
const UNASSIGNED_INSTRUCTOR_NAME = "UNASSIGNED";

const convertStudentAssocToOutput = (school_id: string, associations: SchoolAssociation[]): SchoolAssociationOutput => {
    const students = new Map<InstructorId, Map<StudentId, SchoolAssociation>>();
    // Build a map of student to instructor with assignment date so that we can find the mapping
    // for a student and instructor
    const assignmentMap = new Map<StudentId, AssignmentEntry>();
    associations.filter(association => association.school_id === school_id).forEach(association => {
        const instructor_id = association.is_assigned ? association.instructor_id : UNASSIGNED_INSTRUCTOR_ID;
        if (!assignmentMap.has(association.student_id)) {
            assignmentMap.set(association.student_id, {
                instructor_id,
                start_date: new Date(association.assignment_start_date),
                active_assignment: association.is_assigned,
                association
            });
            return;
        }

        const entry = assignmentMap.get(association.student_id)!;
        const assignment_start_date = new Date(association.assignment_start_date);
        if (assignment_start_date > entry.start_date) {
            assignmentMap.set(association.student_id, {
                instructor_id,
                start_date: assignment_start_date,
                active_assignment: association.is_assigned,
                association
            });
        }
    });

    [...assignmentMap.keys()].forEach(studentId => {
        const debug = studentId === "cf7569fb";
        const assignment = assignmentMap.get(studentId);
        if (!assignment) {
            console.warn("No association found for student", studentId);
            return;
        }
        const instructor_id = assignment.instructor_id;
        const instructor_name = instructor_id === UNASSIGNED_INSTRUCTOR_ID ? UNASSIGNED_INSTRUCTOR_NAME : assignment.association.instructor_name;
        debug && console.log("Adding student", studentId, instructor_id, instructor_name);
        if (!students.has(instructor_id)) {
            students.set(instructor_id, new Map());
        }
        students.get(instructor_id)?.set(studentId, { ...assignment.association, instructor_id, instructor_name });
    });

    return { students };
}

const studentAssociations = async (school_id: string): Promise<SchoolAssociationOutput> => {
    const url = `/schools/${school_id}/associations`;
    const { data } = await authorizedBackendGet<{ associations: SchoolAssociation[] }>(url);
    const results = convertStudentAssocToOutput(school_id, data.associations);
    return results;
};

export const useSchoolAssociations = (school_id: string) => {
    return useQuery<SchoolAssociationOutput, Error>({
        queryKey: STUDENT_QUERY_KEY.concat(school_id),
        queryFn: () => studentAssociations(school_id),
        notifyOnChangeProps: "all",
        ...INSTRUCTIONAL_LOG_REFETCH_SETTINGS
    });
};

/////////////////////////// Instructor Associations ///////////////////////////

type InstructorAssociation = {
    districtId?: string;
    email?: string;
    firstName: string;
    lastName: string;
    userId: string;
}

type InstructorWireFormat = {
    district_id?: string;
    email?: string;
    first_name: string;
    last_name: string;
    user_id: string;
}

const instructorAssociations = async (school_id: string): Promise<InstructorAssociation[]> => {
    const url = `/schools/${school_id}/instructors`;
    const { data } = await authorizedBackendGet<{ instructors: InstructorWireFormat[] }>(url);
    const results = convertInstructorAssocToOutput(data.instructors);
    return results;
};

const convertInstructorAssocToOutput = (instructors: InstructorWireFormat[]): InstructorAssociation[] => {
    return instructors.map(instructor => ({
        districtId: instructor.district_id,
        email: instructor.email,
        firstName: instructor.first_name,
        lastName: instructor.last_name,
        userId: instructor.user_id
    }))
}

export const useInstructorsForSchool = (school_id: string) => {
    const queryClient = useQueryClient();
    const [updatingInstructors, setUpdatingInstructors] = useState(false);
    const { startOverlay, endOverlay } = useHardRefreshOverlay();
    const query = useQuery<InstructorAssociation[], Error>({
        queryKey: INSTRUCTOR_QUERY_KEY.concat(school_id),
        queryFn: () => instructorAssociations(school_id),
        staleTime: 1000 * 60 * 60, // 1 hour,
    });

    const setInstructorForStudent = useMutation({
        mutationKey: INSTRUCTOR_QUERY_KEY.concat("set_instructor", school_id),
        mutationFn: async (data: { student_id: string, instructor_id: string }) => {
            setUpdatingInstructors(true);
            startOverlay();
            const url = `/schools/${school_id}/associations`;
            await authorizedBackendPost(url, {
                body: data
            });
        },
        onSettled: () => {
            queryClient.invalidateQueries();
            queryClient.refetchQueries();
            setUpdatingInstructors(false);
            endOverlay();
        },
    })

    return { query, setInstructorForStudent, updatingInstructors }
};