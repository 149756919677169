import { DatePickerInput } from '@mantine/dates';
import "./DateRange.css";

type Props = {
    startDate: Date;
    setDate: (date: Date) => void;
}

const DateRange: React.FC<Props> = ({ startDate, setDate }) => {
    const value = {
        startDate: startDate,
        endDate: startDate
    }
    const setValue = (value: any) => {
        if (!value) return;
        setDate(value);
    }
    return (<div className="flex flex-col gap-3">
        <div className="w-fit border rounded">
            {/* <Datepicker value={value} onChange={setValue} asSingle startWeekOn="mon" /> */}
            <DatePickerInput
                value={value.startDate}
                onChange={setValue}
                placeholder="Select date" />
        </div>
    </div>)
}

export default DateRange;